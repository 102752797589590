import { Alert, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, LinearProgress, Radio, RadioGroup, Switch, TextField, ToggleButton, Tooltip, Typography, ListItemText, InputLabel, Select, MenuItem, ListItemIcon, Autocomplete, Pagination, Input, Slider } from '@mui/material';
import { ReactComponent as Logo } from './assets/logo.svg';
import './App.scss';
import React, { useEffect, useReducer, useState,useMemo } from 'react';
import { AppContextProvider, useAppContext } from './AppContext';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff, Search, Chat, Settings } from '@mui/icons-material';
import { htmlDecode, stopWords, processListOfWordsArray } from './utils'
import { Header } from './Components'
import Globasearch from './assets/globasearch.png'
import GlobasearchWhite from './assets/global2.png'
import Sparkle from './assets/sparkle1.png'
import ArrowIcon from './assets/arrow.png'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import ShareIcon from '@mui/icons-material/Share';
import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import SettingsPage from './Components/Settings/settings';
import ReactMarkdown from 'react-markdown';
import "katex/dist/katex.min.css"
import RemarkMath from 'remark-math'
import RemarkBreaks from 'remark-breaks'
import RehypeKatex from 'rehype-katex'
import RemarkGfm from 'remark-gfm'
import RehypeHighlight from 'rehype-highlight'
import MarkdownTableRenderer from './Components/TableRenderer/MarkdownTableRenderer'
import { flushSync } from 'react-dom';
import { Button as AntdButton } from 'antd';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Collapse, Divider } from 'antd'
import Highlighter from 'react-highlight-words';
import { Select as AntSelect } from 'antd/lib'

// import  {URL}  from 'url';

import {
  createBrowserRouter,
  redirect,
  RouterProvider,
  useNavigate
} from "react-router-dom";

import { doctypes } from './doctypes';
import axios from 'axios';

const PAGETITLE = 'Enterprise Search'



const drawerWidth = 230

const openedMixin = (theme) => ({
  width: drawerWidth,
  position: 'sticky !important',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: 'sticky !important',
  overflowX: 'hidden',
  // width: calc(${theme.spacing(7)} + 1px),
  width: `calc(35px + 1px)`,
  [theme.breakpoints.up('sm')]: {
    // width: calc(${theme.spacing(8)} + 1px)
    width: `calc(35x + 1px)`
  }
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)
const { Panel } = Collapse

const BQRecordProject = ({ data }) => {
  return (
    <div className='flex-col p-0'>
      <a href={htmlDecode(data.url)} target='blank'><Typography variant='h6' className='font_size16'>{htmlDecode(data.project_name)} ({htmlDecode(data.id)})</Typography></a>
      <Typography variant='subtitle1' className='font_size14'><b>Region:</b> {htmlDecode(data.regionname)}  <b>Country:</b> {htmlDecode(data.countryname)}</Typography>
      <Typography variant='subtitle1' className='font_size14'><b>Project Status:</b> {htmlDecode(data.projectstatusdisplay)} <b>Last Stage Reached:</b> {htmlDecode(data.last_stage_reached_name)} </Typography>
      <Typography variant='body1' className='font_size14'><b>PDO:</b> {htmlDecode(data.pdo)}</Typography>
    </div>
  )
}

const BQRecordDocuments = ({ data }) => {
  const jsonData = JSON.parse(data.jsonData)
  return (
    <div className='flex-col p-0'>
      <a href="javascript:void(0)"> <Typography variant='h6' className='font_size16'>{htmlDecode(jsonData.project_name)} ({htmlDecode(jsonData.id)})</Typography></a>
      <Typography variant='subtitle1' className='font_size14'><b>Region:</b> {htmlDecode(jsonData.region)}  <b>Country:</b> {htmlDecode(jsonData.country)}</Typography>
      <Typography variant='subtitle1' className='font_size14'><b>Date:</b> {htmlDecode(jsonData.date)} <b>Owner:</b> {htmlDecode(jsonData.owner)} </Typography>
      {jsonData.themes.length > 0 && <><Typography variant='subtitle1' className='font_size14'><b>Themes</b></Typography>
        <div className='flex-row'>{jsonData.themes.map(t => <span >{htmlDecode(t)}</span>)}</div></>}
    </div>
  )
}
const BQRecordPeople = ({ data }) => {
  // const jsonData = JSON.parse(data.jsonData)
  return (
    <div className='flex-col p-0'>
      <a href={`https://intranet.worldbank.org/people/profile/${data.modified_upi}`} target='blank'> <Typography variant='h6' className='font_size16'>{htmlDecode(data.fullname)} ({htmlDecode(data.modified_upi)})</Typography></a>
      <Typography variant='subtitle1' className='font_size14'><b>Job Title:</b> {htmlDecode(data.jobtitle)}  <b>Duty Station:</b> {htmlDecode(data.dutystation)}</Typography>
      <Typography variant='subtitle1' className='font_size14'><b>Email:</b> {htmlDecode(data.email)} <b>Room No:</b> {htmlDecode(data.roomnumber)} </Typography>
      <Typography variant='subtitle1' className='font_size14'><b>Official Unit:</b> {htmlDecode(data.officialunit)} <b>Specialization:</b> {htmlDecode(data.specialization)} </Typography>
      <Typography variant='subtitle1' className='font_size14'><b>Language:</b> {htmlDecode(data.language)} <b>Skills:</b> {htmlDecode(data.skills)} </Typography>
      {/* {data.themes.length > 0 && <><Typography variant='subtitle1'><b>Themes</b></Typography>
        <div className='flex-row'>{data.themes.map(t => <span >{htmlDecode(t)}</span>)}</div></>} */}
    </div>
  )
  // return (
  //   <div>{JSON.stringify(data)}</div>
  // )
}

const SearchResult = ({ data, index, colorCode, expandquery }) => {
  const [score, result] = data
  const { metadata, page_content, relevanceScore } = result

  const { wb_collection, wb_source } = metadata
  const isStructured = () => wb_source === 'bigquery'

  const parseStructuredData = () => {
    return page_content.split('\n').reduce((acc, cur) => {
      let clean = cur.split(': ')//.map(c => c.replaceAll(/\t|['"{}]/g, '')).filter(c => c !== '')
      acc[clean[0]] = clean.slice(1, clean.length).join(': ')
      return acc
    }, {})

  }
  const convertToPdfUrl = (profileUrl) => {
    if (!profileUrl) return null;
    const parsedUrl = new URL(profileUrl);
    const pathComponents = parsedUrl.pathname.split('/');
    const curatedIndex = pathComponents.indexOf('curated');
    pathComponents.splice(curatedIndex + 1, 0, 'pdf');
    const pdfUrl = parsedUrl.origin + pathComponents.join('/') + '.pdf';
    return pdfUrl;
  };


  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const highlightAbstract = (text, query) => {
    if (!query) {
      return text;
    }

    let highlightedText = text.replace(/<[^>]*>/g, ''); // Remove existing HTML tags

    const queryWords = query
      .split(' ')
      .filter(word => !stopWords.has(word.toLowerCase()));

    let proccesedWords = processListOfWordsArray(queryWords)

    proccesedWords.forEach(word => {
      const escapedWord = escapeRegExp(word);
      const regex = new RegExp(`(\\b${escapedWord}\\b)(?![^<]*>|[^<>]*</)`, 'gi');
      highlightedText = highlightedText.replace(regex, (match) => `<b>${match}</b>`);
    });

    return highlightedText;
  };

  const dynamicRender = () => {
    if (wb_source === 'bigquery') {
      const structured_data = parseStructuredData()
      switch (wb_collection) {
        case 'documents':
          return <BQRecordDocuments data={structured_data} />
        case 'people':
          return <BQRecordPeople data={structured_data} />
        case 'projects':
          return <BQRecordProject data={structured_data} />
        default:
          return <></>
      }
    } else {
      const pdfUrl = convertToPdfUrl(metadata?.profileurl);
      return (
        <div className='flex-col p-0'>
          <a href={htmlDecode(metadata?.profileurl || metadata?.source)} target='blank'><Typography variant='h6' className='font_size16'>{htmlDecode(metadata?.project_name || metadata?.title || metadata?.fullname + (metadata?.upi ? " ( UPI: " +metadata?.upi + " )" : ""))}</Typography></a>
          <Typography variant="subtitle1" className="font_size14"><b>Extracted Segments: </b>
            <span dangerouslySetInnerHTML={{ __html: highlightAbstract(htmlDecode(page_content), expandquery) }} />

          </Typography>
          {/* <Typography variant="subtitle1" className="font_size14"><b>Extracted Answers: </b>
          <span dangerouslySetInnerHTML={{ __html: highlightAbstract(htmlDecode(extractive_answers), expandquery) }} />
          </Typography>
          <Typography variant="subtitle1" className="font_size14"><b>Snippets: </b>
            <span dangerouslySetInnerHTML={{ __html: highlightAbstract(htmlDecode(snippets), expandquery) }} /> */}

          {/* </Typography> */}
          <Typography variant='subtitle1' className="font_size14"><b>URL: </b> <a href={metadata?.wb_collection==='people'? (metadata?.profileurl):( pdfUrl || metadata?.source)} target='blank'>{metadata?.wb_collection==='people'? (metadata?.profileurl):( pdfUrl || metadata?.source)}</a></Typography>
          {
            metadata?.wb_collection==='documents' && (
              <>
                <Typography variant='subtitle1' className="font_size14"><b>DOCM_ID: </b> {htmlDecode(metadata?.docmid || '-')}</Typography>
                <Typography variant='subtitle1' className="font_size14"><b>Region: </b>{htmlDecode(metadata?.region || 'Not mentioned')}</Typography>
                <Typography variant='subtitle1' className="font_size14"><b>Document Type: </b>{htmlDecode(metadata?.documenttype || "None")}</Typography>
                <Typography variant='subtitle1' className="font_size14"><b>Country: </b>{htmlDecode(metadata?.countryshortname || 'Not Mentioned')}</Typography>
              </>
            )
          }

          {
            metadata?.wb_collection==='people' && (
              <>
                <Typography variant='subtitle1' className="font_size14"><b>Duty Station: </b> {htmlDecode(metadata?.dutystation || 'Not mentioned')}</Typography>
                <Typography variant='subtitle1' className="font_size14"><b>Job Title: </b>{htmlDecode(metadata?.jobtitle || 'Not mentioned')}</Typography>
                <Typography variant='subtitle1' className="font_size14">
                  <b>Skills: </b>
                  {Array.isArray(metadata?.skills) ? htmlDecode(metadata.skills.join(" & ")) || "None" : htmlDecode(metadata?.skills || "None")}
                </Typography>

                <Typography variant='subtitle1' className="font_size14">
                  <b>Specialization: </b>
                  {Array.isArray(metadata?.skills) ? htmlDecode(metadata.skills.join(" & ")) || 'Not Mentioned' : htmlDecode(metadata?.skills || 'Not Mentioned')}
                </Typography>
                <Typography variant='subtitle1' className="font_size14"><b>Languages: </b>{htmlDecode(metadata?.languages || 'Not mentioned')}</Typography>
                <Typography variant='subtitle1' className="font_size14"><b>Official Unit: </b>{htmlDecode(metadata?.officialunit || 'Not mentioned')}</Typography>
                <Typography variant='subtitle1' className="font_size14"><b>Organization: </b>{htmlDecode(metadata?.organization || 'Not mentioned')}</Typography>
                <Typography variant='subtitle1' className="font_size14"><b>VPU: </b>{htmlDecode(metadata?.vpu || 'Not mentioned')}</Typography>
                {/* <Typography variant='subtitle1' className="font_size14"><b>METADATA: </b>{JSON.stringify(metadata || {},null,2)}</Typography> */}


              </>
            )
          }


          
          <Typography variant='subtitle1' className="font_size14"><b>Date: </b>{htmlDecode(metadata?.documentdate || metadata?.date || metadata?.year)}</Typography>
          <Typography variant='subtitle1' className="font_size14"><b>Relevancy: </b>{htmlDecode(relevanceScore)}</Typography>
        </div>)
    }
  }

  return (
    <Card className="cardDesign">
      <CardContent className={'flex-col p-0'}>
        {/* - <b>Relevance:</b> {(score * 100).toFixed(2)}% */}

        <div className={`flex-row-sm ${colorCode ? `${isStructured() ? 'red-bg' : 'green-bg'}` : ''}`}>
          <code className='font_size14'>[{index}]</code>
          <Typography variant='subtitle1' className="font_size14"><b>Collection</b>: {wb_collection} - <b>Source:</b> {wb_source} </Typography>
        </div>
        {dynamicRender()}
      </CardContent>
    </Card>
  )
}

const Linkify = ({ children }) => {
  const isUrl = word => {
    const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    return word.match(urlPattern);
  };

  const addMarkup = word => {
    return isUrl(word) ? <a href={word}>{word}</a> : word
  };

  const words = children.split(' ');
  const formatedWords = words.map((w, i) => addMarkup(w));
  return (<span>{formatedWords}</span>);
};

const SummaryPanel = () => {
  const { summary, showConverse, setShowConverse, loadingState, hasRunSummary, loadingTime, result, intentDict, entitiesDict } = useAppContext()
  const source_link = summary?.split('SOURCES:')[1]
  const summary_text = summary?.split('SOURCES:')[0]
  const [checkTable, setCheckTable] = useState(false)
  useEffect(() => {
    // console.log('22222', summary_text)

    if (summary_text?.toLowerCase().includes('|' || 'table')) {
      setCheckTable(true)
    } else {
      setCheckTable(false)
    }
  }, [summary_text])

  // console.log('444444444444',summary_text)
  // console.log('checkTable', checkTable)
  return (
    hasRunSummary ? (
      <div id='summary-box'>
        <div className='flex-col'>
          {/* <Typography variant='h4'>Summary</Typography> */}
          {/* ✨ Generated by AI ✨ */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='gray' variant='subtitle2' className="generate_head"> <img src={Globasearch} alt="" /> Generated by AI - be cautious with results</Typography>

            <div className='DetailPerc'>
              <span className='searchTime'><b>Regions</b> : {entitiesDict?.region || "None"} </span> |
              <span className='searchTime'><b>Countires</b> : {entitiesDict?.country || "None"} </span> |
              <span className='searchTime'><b>DocType</b> : {entitiesDict?.doctype || "None"} </span> |
              <span className='searchTime'><b>Documents</b> : {intentDict?.documents ? intentDict?.documents.toFixed(2) : 0}% </span> |
              <span className='searchTime'><b>Projects</b> : {intentDict?.projects ? intentDict?.projects.toFixed(2) : 0}%  </span> |
              <span className='searchTime'><b>People</b> : {intentDict?.people ? intentDict?.people.toFixed(2) : 0}%  </span> |
              <span className='searchTime'><b>Search Time</b> : {loadingTime.toFixed(2)}s</span>
            </div>
          </div>
          <div className='italic-text' style={{ whiteSpace: 'pre-line' }}>
            {/* <Typography variant='body1'><ReactMarkdown> {summary_text}</ReactMarkdown></Typography> */}
            <Typography variant='body1'>
              {/* <ReactMarkdown children={summary_text} components={
                {
                  a: ({ node, ...props }) => <Linkify>{props.children}</Linkify>,
                  // for all p tags, have minimum spacing in sytle
                  p: ({ node, ...props }) => <p style={{ margin: '0px' }}>{props.children}</p>,
                  ul: ({ node, ...props }) => <ul style={{ margin: '0px' }}>{props.children}</ul>,
                  ol: ({ node, ...props }) => <ol style={{ margin: '0px' }}>{props.children}</ol>,
                  li: ({ node, ...props }) => <li style={{ margin: '0px' }}>{props.children}</li>,
                  div: ({ node, ...props }) => <div style={{ margin: '0px' }}>{props.children}</div>,
                  span: ({ node, ...props }) => <span style={{ margin: '0px' }}>{props.children}</span>,


                }
              } /> */}
              {checkTable ?
                <MarkdownTableRenderer markdownText={summary_text} /> :
                <ReactMarkdown
                  className='markfont'
                  remarkPlugins={[RemarkMath, RemarkGfm, RemarkBreaks]}
                  rehypePlugins={[
                    RehypeKatex,
                    [
                      RehypeHighlight,
                      {
                        detect: false,
                        ignoreMissing: true,
                      },
                    ],
                  ]}
                >
                  {summary_text}
                </ReactMarkdown>
              }
            </Typography>
            {source_link && <div className='sourcelink'><p><strong> Sources:</strong></p><Linkify>{source_link}</Linkify></div>}
          </div>
          {summary === '' &&
            <div className='skeltonBackground'>
              <Box >
                <Skeleton />
                <Skeleton animation="wave" style={{ marginBottom: '40px' }} />
                <Skeleton sx={{ width: '70%' }} />
                <Skeleton animation="wave" sx={{ width: '50%' }} />
                {/* <Skeleton animation={false} /> */}
              </Box>
            </div>
          }
          {!showConverse && <Button style={{ marginTop: '20px' }} onClick={() => setShowConverse(true)}>Ask follow up question?</Button>}
        </div>
      </div>
    ) :
      <div id='summary-box'>
        <div className='flex-col'>
          {/* <Typography variant='h4'>Summary</Typography> */}
          {/* <Typography color='gray' variant='subtitle2' className="generate_head"> <img src={Globasearch} alt="" /> Generated by AI - be cautious with results</Typography> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='gray' variant='subtitle2' className="generate_head"> <img src={Globasearch} alt="" /> Generated by AI - be cautious with results</Typography>
            <div className='DetailPerc'>
              <span className='searchTime'><b>Documents</b> : 0% </span> |
              <span className='searchTime'><b>Projects</b> : 0% </span> |
              <span className='searchTime'><b>People</b> : 0% </span> |
              <span className='searchTime'><b>Search Time</b> : {loadingTime.toFixed(2)}s</span>
            </div>
          </div>
          <div className='italic-text' style={{ whiteSpace: 'pre-line' }}>
            <Typography variant='body1'> {loadingState.summary && <LinearProgress />}</Typography>
          </div>
          {loadingState.summary &&
            <div className='skeltonBackground'>
              <Box >
                <Skeleton />
                <Skeleton animation="wave" style={{ marginBottom: '40px' }} />
                <Skeleton sx={{ width: '70%' }} />
                <Skeleton animation="wave" sx={{ width: '50%' }} />
                {/* <Skeleton animation={false} /> */}
              </Box>
            </div>
          }
        </div>
      </div>
  )
}

const ChatResult = ({ type, msg }) => {
  return (
    <div className={`chat-msg ${type === 'ai' ? 'ai-msg' : 'human-msg'}`}>
      <div className={`chat-msg--text ${type === 'ai' ? 'chat-msg--text-ai' : 'chat-msg--text-human font_Chnage'}`}  >
        {type != 'ai' && <span className='humanIcon'><PersonIcon /> </span>}
        <Typography style={{ flex: 1, whiteSpace: 'pre-line' }} className='font_size14 ' >
          {msg}
        </Typography>
      </div>
    </div>
  )
}

const ConversePanel = () => {
  const { converseQuery, setConverseQuery, setShowConverse, handleConverse, handleKeyDownConverse, loadingState, conversation, searchNewQuery, runNewSearch } = useAppContext()
  useEffect(() => {
    // Scroll to the bottom when conversation changes
    window.scrollTo(0, document.body.scrollHeight);
  }, [conversation]);

  console.log("conversation", conversation)
  return (
    <div className='flex-col' onKeyDown={handleKeyDownConverse}>

      <div className='flex-col'>
        <div>
          {conversation?.slice().reverse().map((c, idx) => {
            return (
              <ChatResult msg={c.msg} key={idx} type={c.type} />
            )
          })}
        </div>
      </div>
      {
        searchNewQuery && <div className='flex-col'>
          <div className='chat-msg--text chat-msg--text-ai'>
            <Typography style={{ flex: 1, whiteSpace: 'pre-line' }} className='font_size14 ' >Do you want to run a new search ?
              <button onClick={() => runNewSearch(1, conversation ? conversation[1].msg : '')} className='YesBtn' >YES</button>
              <button onClick={() => runNewSearch(0, null)} className='NoBtn' >NO</button>
            </Typography>

          </div>
        </div>
      }

      <div className='conversationMain'>
        {/* <Button color='error' className='coversationClose' onClick={() => setShowConverse(false)}>Close</Button> */}
        <div style={{ flex: 1 }}>
          <TextField
            disabled={loadingState.conversation}
            fullWidth
            value={converseQuery}
            onChange={(e) => setConverseQuery(e.target.value)}
            className='conversationInput'
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton onClick={handleConverse}>
                  <Chat />
                </IconButton>
                <IconButton onClick={() => setShowConverse(false)}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>,
            }}
          />
          {loadingState.conversation && <LinearProgress />}
        </div>
      </div>


    </div>
  )
}

const ResultPanel = ({ pageNumber, setPageNumber }) => {
  const [colorCode, setColorCode] = useState(false)

  // const [filter, setFilter] = useReducer((state, payload) => state.includes(payload) ? state.filter(f => f !== payload) : [payload].concat(state), ['vertex search'])
  // data.result.metadata.wb_source
  const { result, loadingState, isNextPage, hasRunSearch, handleCitation, totalResults, expandqury, noOfRows, setNoOfRows, pageNo, setPageNo } = useAppContext()
  const vertexResult = result?.filter(r => 
    r[1].metadata?.wb_source === "vertex search" || r[1].metadata?.wb_source === "bigquery"
  ) || []
  // const handlePrevious = () => {
  //   if (pageNumber > 1) {
  //     flushSync(() => {
  //       setPageNumber(pageNumber - 1);
  //       handleCitation(false, null, true, true, pageNumber - 1);
  //     });
  //     window.scrollBy({
  //       top: -document.body.scrollHeight,
  //       left: 0,
  //       behavior: 'smooth'
  //     });
  //   }
  // };

  // const handleNext = () => {
  //   if (isNextPage) {
  //     flushSync(() => {
  //       setPageNumber(pageNumber + 1);
  //       handleCitation(false, null, true, true, pageNumber + 1);
  //     });
  //     window.scrollBy({
  //       top: -document.body.scrollHeight,
  //       left: 0,
  //       behavior: 'smooth'
  //     });
  //   }
  // };



  const handleChangeRowsPerPage = (e) => {
    setNoOfRows(e)
    flushSync(() => {
      setPageNo(1)
      setPageNumber(1);
      handleCitation(false, null, true, true, 1, e);
    });
    window.scrollBy({
      top: -document.body.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  const getPage = (e, pageNumber) => {
    flushSync(() => {
      setPageNo(pageNumber)
      setPageNumber(pageNumber);
      handleCitation(false, null, true, true, pageNumber, noOfRows);
    });
    window.scrollBy({
      top: -document.body.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }



  return (
    <div className='flex-col'>

      <span><FormControlLabel label="Show source by colour" control={<Switch checked={colorCode} onChange={(e) => setColorCode(e.target.checked)} />} /></span>
      {/* <FormGroup > */}
      <span><FormLabel>Included sources</FormLabel></span>
      {/* <span><FormControlLabel control={<Checkbox checked={filter.includes('vertex search')} onChange={() => setFilter('vertex search')} />} label="Vertex" /></span> */}
      {/* <span><FormControlLabel control={<Checkbox checked={filter.includes('bigquery')} onChange={() => setFilter('bigquery')} />} label="BigQuery" /></span> */}
      {/* </FormGroup> */}

      <Typography variant='h4' className="overReult">More than {totalResults} results</Typography>
      {vertexResult?.length > 0 ? (
        <div className='flex-col' style={{ padding: 0 }}>
          {vertexResult.map((r, idx) => <SearchResult data={r} key={idx} index={idx + 1} colorCode={colorCode} expandquery={expandqury} />)}
        </div>
      )
        : loadingState?.search ? (
          // Render skeleton loading component when there are no search results
          <div className='skeltonBackground'>
            <Box>
              <Skeleton animation="wave" />
              <Skeleton sx={{ width: '70%', marginBottom: '20px' }} />
              <Skeleton animation="wave" sx={{ width: '50%', marginBottom: '20px' }} />
              <Skeleton sx={{ width: '70%', marginBottom: '20px' }} />
              <Skeleton animation="wave" sx={{ width: '90%', marginBottom: '20px' }} />
              <Skeleton animation="wave" sx={{ width: '50%', marginBottom: '20px' }} />
            </Box>
          </div>
        ) : null
      }
      <div className='pagination-container'>
        {/* <button className='pagination-button' onClick={handlePrevious} disabled={loadingState?.search || pageNumber === 1}>Previous</button>
        <b className='pagination-page-number'>{pageNumber} {loadingState?.search} {hasRunSearch}</b>
        <button className='pagination-button' onClick={handleNext} disabled={loadingState?.search || !isNextPage}>Next</button> */}

        {/* <AntdButton shape="circle" size={'default'} className='btnpagination' style={{ marginRight: '10px' }} onClick={handlePrevious} disabled={loadingState?.search || pageNumber === 1}><ArrowBackIosIcon style={{ marginLeft: '5px' }} /></AntdButton>
        <p className='pagination-page-number'>{pageNumber} {loadingState?.search} {hasRunSearch}</p>
        <AntdButton shape="circle" size={'default'} className='btnpagination' style={{ marginLeft: '10px' }} onClick={handleNext} disabled={loadingState?.search || !isNextPage}><ArrowForwardIosIcon style={{ marginLeft: '0px' }} /></AntdButton> */}
      </div>
      {totalResults > 0 ?
        <div className='pagination-list'>
          <Pagination
            color="primary"
            variant='outlined'
            shape='rounded'
            count={Math.ceil(totalResults / noOfRows)}  // Total count of rows
            page={pageNo}
            onChange={getPage}
          />

          <span className='rowsPerPage'>
            <div>
              <p>
                Rows per page:
              </p>
            </div>
            <AntSelect
              style={{
                width: 70,
                marginLeft: 5
              }}
              value={noOfRows}
              optionFilterProp='children'
              onChange={handleChangeRowsPerPage}
              options={[
                {
                  value: 10,
                  label: 10,
                },
                {
                  value: 20,
                  label: 20,
                },
                {
                  value: 50,
                  label: 50,
                }
              ]}
            />
          </span>
        </div> : null

      }

    </div>
  )
}

const Timer = () => {
  const { loadingTime, setLoadingTime } = useAppContext()

  useEffect(() => {
    const start = Date.now();
    const interval = setInterval(() => {
      let delta = Date.now() - start;
      setLoadingTime(delta / 1000);
    }, 100);
    return () => {
      clearInterval(interval)
    }
  }, []);

  return <div className='timer-text'>{loadingTime.toFixed(2)}s</div>;
}

const peopleDS = {
  0: ['None', 'None'],
  1: ['people_unstructured', 'Unstructured People DS'],
  2: ['people_structured', 'Structured People DS']
};

const projectDS = {
  0: ['None', 'None'],
  1: ['projects_unstructured', 'Unstructured Projects DS'],
  2: ['projects_structured', 'Structured Projects DS']
};

const SearchPanel = ({ notchang, parent_func }) => {
  const {
    hasRunSearch,
    searchQuery,
    setSearchQuery,
    handleSearch,
    handleKeyDownSearch,
    handleKeyDownSearchClick,
    loadingState,
    structuredApproach,
    setStructuredApproach,
    loadingTime,
    clearTests,
    downloadTests,
    testData,
    runAllTests,
    setIsHome,
    isHome,
    setThresholdValue,
    setBostValue,
    bostValue,
    thresholdValue,
    setBostValueDecrement,
    bostValueDecrement,
    setVertexthresholdValue,
    vertexthresholdValue,
    setRegionBoost,
    regionBoost,
    setRecencyBoost,
    recencyBoost,
    isPeopleIntent,
    setIsPeopleIntent,
    isProjectIntent,
    setIsProjectIntent,
    setCountryBoost,
    countryBoost,
    setDocTypeBoost,
    docTypeBoost,
    docTypeFilter,
    setDocTypeFilter,
    maxPageAccess,
    setMaxPageAccess,
    expandSynonym,
    setExpandSynonym,
    useClaude,
    setUseClaude,
    selectAll,
    setSelectAll,
    tempValue,
    setTempValue,
    modelDict,
    modelName,
    setModelName,
    isWildCard,
    setIsWildCard,
  } = useAppContext()
  const [expanded, setExpanded] = useState(false)
  const [configExpanded, setConfigExpanded] = useState(false)


  const [searchTerm, setSearchTerm] = useState('');





  const handleSearchdomain = (event) => {
    setSearchTerm(event.target.value);
  };


  // const handleSelectAll = (event) => {
  //   const { checked } = event.target;
  //   if (checked) {
  //     console.log('Array(1)', checked, doctypes)
  //     setDocTypeFilter(doctypes);
  //   } else {
  //     setDocTypeFilter([]);
  //   }
  // };

  const handleSelectAll = () => {
    if (docTypeFilter.length === doctypes.length) {
      setDocTypeFilter([]);
    } else {
      setDocTypeFilter(doctypes);
    }
  };

  useEffect(() => {
    setIsHome(notchang)
  }, [notchang])
  const handleRunAllTests = () => {
    runAllTests(['Lessons learned in Nepal', 'Lessons learned projects in Nepal'], ['hardcoded', 'experimental'])
  }

  const handleChangeInput = (event) => {
    const inputValue = event.target.value;
    if (/^([01](\.\d*)?)?$/.test(inputValue) || inputValue === '') {
      if (inputValue <= 1 && !inputValue.includes('1.')) {
        setThresholdValue(inputValue)
      }
      else {
        setThresholdValue('1')
      }
    }
  };

  const handleChangeInputvertex = (event) => {
    const inputValue = event.target.value;
    if (/^([01](\.\d*)?)?$/.test(inputValue) || inputValue === '') {
      if (inputValue <= 1 && !inputValue.includes('1.')) {
        setVertexthresholdValue(inputValue)
      }
      else {
        setVertexthresholdValue('1')
      }
    }
  };
  const handleChangeInputboost = (event) => {
    const inputValue = event.target.value;
    if (/^([01](\.\d*)?)?$/.test(inputValue) || inputValue === '') {
      if (inputValue <= 1 && !inputValue.includes('1.')) {
        setBostValue(inputValue)
      }
      else {
        setBostValue('1')
      }
    }
  };

  const handleChangeInputTemperature = (event) => {
    const inputValue = event.target.value;
    if (inputValue <= 1) {
        setTempValue(inputValue)
      }
    else {
        setTempValue(0.0)
      }
  }


  const handleChangeDoctypeFilter = (event, newValue) => {
    setDocTypeFilter(newValue);
  };
  const handleChangeboostDecrement = (event) => {
    const inputValue = event.target.value;
    if (/^([01](\.\d*)?)?$/.test(inputValue) || inputValue === '') {
      if (inputValue <= 1 && !inputValue.includes('1.')) {
        setBostValueDecrement(inputValue)
      }
      else {
        setBostValueDecrement('1')
      }
    }
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setRegionBoost(checked);
    setCountryBoost(checked);
    setRecencyBoost(checked);
    setDocTypeBoost(checked);
    setExpandSynonym(checked);
    setUseClaude(checked);
    setSelectAll(checked);
    // setIsPeopleIntent(checked);
  };


  

  const renderPeopleDSItems = useMemo(() => {
    return Object.keys(peopleDS).map(key => (
      <MenuItem key={key} value={peopleDS[key][0]}>
        {peopleDS[key][1]}
      </MenuItem>
    ));
  }, []);

  const renderProjectDSItems = useMemo(() => {
    return Object.keys(projectDS).map(key => (
      <MenuItem key={key} value={projectDS[key][0]}>
        {projectDS[key][1]}
      </MenuItem>
    ));
  }, []);

  const renderModelsItems = useMemo(() => {

    return Object.keys(modelDict).map(key => (
      <MenuItem key={key} value={modelDict[key][0]}>
        {modelDict[key][1]}
      </MenuItem>
    ));
  }, [modelDict]);


  return (
    <div
      className={`${expanded ? 'half-width' : 'half-width'}`}
      onKeyDown={handleKeyDownSearch}
    >
      <div className='search_inputBox'>
        <TextField
          disabled={loadingState.search}
          onFocus={() => setExpanded(true)}
          onBlur={() => setExpanded(false)}
          fullWidth
          value={searchQuery}
          className='search_input search_headerinput'
          onChange={(e) => setSearchQuery(e.target.value)}
          // label='Type here to search'
          placeholder='Migration crisis in latin america'
          InputProps={{
            endAdornment: <InputAdornment position="end" className='search_headerinput_icon'>
              <IconButton onClick={parent_func}>
                <Search />
              </IconButton>
            </InputAdornment>,
          }}
        />
        <InfoOutlinedIcon className='search_inputBox_info' />
      </div>
      <div id='under-search-items'>
        {loadingState.search && <LinearProgress />}
        {loadingState.search
          ? <Timer />
          : (hasRunSearch ? <div className='timer-text'>Search ran in {loadingTime.toFixed(2)}s</div> : <></>)}

        {!hasRunSearch && <div>
          <Button
            onClick={() => setConfigExpanded(!configExpanded)}
            endIcon={<Settings />}
            color={configExpanded ? 'error' : 'primary'}>
            {`${configExpanded ? 'Hide' : 'Show'} Settings`}
          </Button>
        </div>
        }

        {configExpanded && <div id='under-search-config'>
          {/* <Tooltip title='Choose the approach for querying structured data'>
            <FormControl>
              <FormLabel>SQL Generation Method</FormLabel>
              <RadioGroup value={structuredApproach} onChange={(e) => setStructuredApproach(e.target.value)}>
                <FormControlLabel value="hardcoded" control={<Radio />} label="Hardcoded" />
                <FormControlLabel value="dynamic" control={<Radio />} label="Dynamic" />
                <FormControlLabel value="endpoint" control={<Radio />} label="API Endpoint" />
                <FormControlLabel value="experimental" control={<Radio />} label="Experimental Feature" />
                <FormControlLabel value="vector" control={<Radio />} label="Vector Search" />
              </RadioGroup>
            </FormControl>
          </Tooltip> */}
          <div className='mainInputDiv'>
            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Vertex Relevancy Threshold :</span>
              <TextField
                className='thresholdinput'
                value={thresholdValue}
                onChange={handleChangeInput}
                variant="outlined"
              />
            </div>
            {/* <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Vector Relevancy Threshold :</span>
              <TextField
                className='thresholdinput'
                value={vertexthresholdValue}
                onChange={handleChangeInputvertex}
                variant="outlined"
              />
            </div> */}
            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Boost :</span>
              <TextField
                className='thresholdinput'
                value={bostValue}
                onChange={handleChangeInputboost}
                variant="outlined"
              />
            </div>

            {/* <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Max Pages to Access :</span>
              <TextField
                className='thresholdinput'
                value={maxPageAccess}
                variant="outlined"
                type='number'
                InputProps={{ inputProps: { min: 1, max: 100 } }}
                onChange={(e) => setMaxPageAccess(parseInt(e.target.value))}
              // add max value of 100
              // add min value of 1

              />
            </div>
            {/* <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Boost Decrement :</span>
              <TextField
                className='thresholdinput'
                value={bostValueDecrement}
                onChange={handleChangeboostDecrement}
                variant="outlined"
              />
            </div> */}
            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Select All:</span>
              <FormControl>
                <FormControlLabel
                  control={<Checkbox size="small" style={{ width: "30px", height: "25px", marginLeft:"3px" }} checked={selectAll} onChange={handleSelectAllChange} />}
                />
              </FormControl>
            </div>
            {/* <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Search People DS:</span>
              <FormControl>
                <FormControlLabel
                  control={<Checkbox size="small" style={{ width: "30px", height: "25px", marginLeft:"3px" }} checked={isPeopleIntent} onChange={(e) => setIsPeopleIntent(e.target.checked)} />}
                />
              </FormControl>
            </div> */}
            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Region Boost :</span>
              {/* add check box */}
              <FormControl>
                <FormControlLabel
                  control={<Checkbox size="small" style={{ width: "30px", height: "25px", marginLeft:"3px" }} checked={regionBoost} onChange={(e) => setRegionBoost(e.target.checked)} />}
                />
              </FormControl>
            </div>


            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Country Boost :</span>
              {/* add check box */}
              <FormControl>
                <FormControlLabel
                  control={<Checkbox size="small" style={{ width: "30px", height: "25px", marginLeft:"3px" }} checked={countryBoost} onChange={(e) => setCountryBoost(e.target.checked)} />}
                />
              </FormControl>
            </div>

            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Recency Boost :</span>
              {/* add check box */}
              <FormControl>
                <FormControlLabel
                  control={<Checkbox size="small" style={{ width: "30px", height: "25px", marginLeft:"3px" }} checked={recencyBoost} onChange={(e) => setRecencyBoost(e.target.checked)} />}
                />
              </FormControl>
            </div>

            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>DocType Boost :</span>
              {/* add check box */}
              <FormControl>
                <FormControlLabel
                  control={<Checkbox size="small" style={{ width: "30px", height: "25px", marginLeft:"3px" }} checked={docTypeBoost} onChange={(e) => setDocTypeBoost(e.target.checked)} />}
                />
              </FormControl>
            </div>

            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Expand Query:</span>
              {/* add check box */}
              <FormControl>
                <FormControlLabel
                  control={<Checkbox size="small" style={{ width: "30px", height: "25px", marginLeft:"3px" }} checked={expandSynonym} onChange={(e) => setExpandSynonym(e.target.checked)} />}
                />
              </FormControl>
            </div>

            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Is Wildcard:</span>
              {/* add check box */}
              <FormControl>
                <FormControlLabel
                  control={<Checkbox size="small" style={{ width: "30px", height: "25px", marginLeft:"3px" }} checked={isWildCard} onChange={(e) => setIsWildCard(e.target.checked)} />}
                />
              </FormControl>
            </div>

            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Model Temperature:</span>
              <TextField
                className='thresholdinput'
                value={tempValue}
                onChange={handleChangeInputTemperature}
                variant="outlined"
                type='number'
                inputProps={{ min: 0, max: 1, step: 0.1 }}
              />
            </div>

            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Model :</span>
              <FormControl>
              <Select
                style={{
                  height: "30px",
                }}
                value={modelName}
                onChange={(e) => {const selectedModel = e.target.value;
                  setModelName(selectedModel);
                  setUseClaude(selectedModel === modelDict[2][0]); // Set useClaude to true if Claude Sonnet is selected
                  }}
              >
                {renderModelsItems}
              </Select>
            </FormControl>
            </div>

            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>People DS :</span>
              <FormControl>
              <Select
                style={{
                  height: "30px",
                }}
                value={isPeopleIntent}
                onChange={(e) => setIsPeopleIntent(e.target.value)}
              >
                {renderPeopleDSItems}
              </Select>
            </FormControl>
            </div>
            
            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>Project DS :</span>
              <FormControl>
              <Select
                style={{
                  height: "30px",
                }}
                value={isProjectIntent}
                onChange={(e) => setIsProjectIntent(e.target.value)}
              >
                {renderProjectDSItems}
              </Select>
            </FormControl>
            </div>


            <div className='mainDataInput'>
              <span className='mainInputDiv_span'>DocType Filter:</span>
              <FormControl style={{ flex: 1 }}>
                <Autocomplete
                  
                  multiple
                  id="docTypeFilter-select"
                  limitTags={2}
                  options={doctypes}
                  value={docTypeFilter}
                  onChange={handleChangeDoctypeFilter}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option} />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Document Types"
                      variant="outlined"
                      placeholder="Search..."
                      onChange={handleSearchdomain}
                    />
                  )}
                  inputValue={searchTerm}
                  isOptionEqualToValue={(option, value) => option === value}
                />
              </FormControl>
              {/* <FormControl style={{ flex: 1 }}>
                <Autocomplete
                  multiple
                  id="docTypeFilter-select"
                  options={doctypes}
                  value={selectedOptions}
                  onChange={handleChange}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option} />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Document Types"
                      variant="outlined"
                      placeholder="Search..."
                      onChange={handleSearch}
                    />
                  )}
                  inputValue={searchTerm}
                  isOptionEqualToValue={(option, value) => option === value}
                />
              </FormControl>

              <FormControl style={{ flex: 1 }}>
                <Select
                    style={{
                      height: "20px"
                    }}
                  labelId="docTypeFilter-label"
                  id="docTypeFilter-select"
                  value={docTypeFilter}
                  onChange={handleChangeDoctypeFilter}
                  multiple
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        marginLeft: '130px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #d3d4d5',
                        maxWidth: '100%',
                      },
                    },
                  }}
                  renderValue={(selected) => selected.join(', ')}
                >
                  <MenuItem key="select-all" value="select-all">
                    <Checkbox
                      checked={docTypeFilter.length === doctypes.length}
                      indeterminate={docTypeFilter.length > 0 && docTypeFilter.length < doctypes.length}
                      onChange={handleSelectAll}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {doctypes.map((value) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox checked={docTypeFilter.indexOf(value) > -1} />
                      <ListItemText primary={value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>

          </div>
          {/* <div className='flex-row'>
            <Button variant='contained' color='info' onClick={handleRunAllTests}>Run MegaTest</Button>
            <Button variant='contained' color='success' onClick={downloadTests} disabled={testData.length === 0}>Download Search Cache</Button>
            <Button variant='contained' color='warning' onClick={clearTests} disabled={testData.length === 0}>Clear Search Cache</Button>
          </div> */}
        </div>}
      </div>
    </div>
  )
}

const PasswordPage = () => {
  const { loadingState, checkLogin, redirectPage, setRedirectPage } = useAppContext()
  const [enteredPW, setEnteredPW] = useState('')
  const [showPW, setShowPW] = useState(false)
  const [pwError, setPwError] = useState(false)
  const navigate = useNavigate();

  const handleChange = (e) => {
    setPwError(false)
    setEnteredPW(e.target.value)
  }

  const handleCheck = async () => {
    checkLogin(enteredPW)
      .then(r => {
        if (!!r) {
          if (redirectPage) {
            navigate(`/${redirectPage}`)
            setRedirectPage()
          } else {
            navigate(0)
          }
        } else {
          setPwError(true)
        }
      })
  }

  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      handleCheck()
    }
  }
  return (
    <div className='pw-fullpage'>
      <div className='pw-content'>
        <div className='pw-content--inner'>
          <div className='pw-content--logo'><Logo /></div>
          <Typography variant='h4' color='gray'>{PAGETITLE}</Typography>
        </div>
        <div className='pw-content--inner'>
          <TextField
            error={pwError}
            style={{ minWidth: '40vw' }}
            label={pwError ? 'Incorrect!' : 'Enter password'}
            type={showPW ? 'text' : "password"}
            value={enteredPW}
            onChange={handleChange}
            onKeyDown={handleSubmit}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    disabled={loadingState.app}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPW(!showPW)}
                    edge="end"
                  >
                    {showPW ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            }}
          />
          <Button variant='contained' onClick={handleCheck}>Submit</Button>
        </div>
        <div className='pw-content--loading'>
          {loadingState.app && <LinearProgress fullWidth />}
        </div>
      </div>
    </div>
  )
}

const SearchAgentApp = () => {
  const { summary, handleGoBack, hasRunSearch, isLoggedIn, setRedirectPage, showConverse, result, hasRunSummary, handleKeyDownSearchClickBox, setSearchQuery, recommendedQues, correctedQuery, setCorrectedQuery, handleKeyDownSearchClick, facets, tempValue,setTempValue, modelName, setModelName, setUseClaude, modelDict} = useAppContext()
  // console.log({summary, hasRunSearch, isLoggedIn, setRedirectPage, showConverse, result})
  const DataArray = [
    'Migration crisis in latin america',
    'What are the main challenges faced in project implementation in HNP',
    "Give me a list of policies in jordan CCDR related to decarbonization"
  ];
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = useState(1)
  const [opendrawer, setOpendrawer] = useState(true)
  const [activeSideMenuKey, setActiveSideMenuKey] = useState(1)

  const parent_handleKeyDownSearchClick = () => {
    setPageNumber(1)
    handleKeyDownSearchClick()
  }


  useEffect(() => {
    if (!isLoggedIn()) {
      setRedirectPage('agent')
      navigate('/login')
    }
  }, [])

  const [valueTab, setValueTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  // const handleHome = () => {
  //   handleGoBack()
  // }

  const handleDrawerOpen = () => {
    setOpendrawer(true)
  }

  const handleDrawerClose = () => {
    setOpendrawer(false)
  }
  const handleSidePanelChange = (value) => {
    setActiveSideMenuKey(value)
  }

  const [elementHeight, setElementHeight] = useState(`calc(100vh - 205px)`);
  // const handleModelChange = (event) => {
  //   const selectedModel = event.target.value;
  //   setModelName(selectedModel);
  //   // if (selectedModel == modelDict[2][0]) {
  //   //   setUseClaude(true);
  //   // }
  //   };

  // const menuItems = [];
  // for (const [index, [modelName, simpleName]] of Object.entries(modelDict)) {
  //   menuItems.push(
  //     <MenuItem key={index} value={modelName}>{simpleName}</MenuItem>
  //   );
  // }
  // const marks = [
  //   {value: 0,
  //   label: '0',
  //   },
  //   {
  //   value: 0.5,
  //   label: '0.5',
  //   },
  //   {
  //   value: 1,
  //   label: '1',
  //   }
  //   ];
  useEffect(() => {
    const handleScroll = () => {
      const distance = window.scrollY;
      if (window.scrollY >= 205) {
        setElementHeight('calc(100vh - 20px)')
      }
      else if (window.scrollY < 205) {
        const height = 205 - Number(distance)
        setElementHeight(`calc(100vh - ${height}px)`);
      }
      else {
        setElementHeight(`calc(100vh - ${distance}px)`);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (


    <div id='main-content' className='flex-col centred' style={{ padding: 0 }}>
      {!hasRunSearch ?
        <div className='main-content-without'>
          <ErrorBar />
          <div className='main_search'>
            <img src={Globasearch} alt="" />
            <Typography variant='h3'>{PAGETITLE}</Typography>
          </div>
          <SearchPanel notchang={false} parent_func={parent_handleKeyDownSearchClick} />
          <div className='half-width'>
            <div className='powerIgnite'>
              <h6>AI-Powered Insights <span>BETA</span></h6>
              <p>Try the new AI-generated summary insights. Get started with these sample questions</p>
              <div className='powerIgnite-exp'>
                {DataArray.map((item, index) => (
                  <div key={index} onClick={() => handleKeyDownSearchClickBox(item)}>
                    <p>{item}</p>
                    <span>
                      <img src={ArrowIcon} alt="" />
                    </span>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>
        :
        <div className='searchHeader_Main'>
          {/* show summary only when hasRunSwarch true */}
          <div className='searchHeader'>
            <div className='searchHeader_sub'>
              <div className='searchHeader-logo'>
                <div className='searchHeader-logo_sub' onClick={() => {
                  handleGoBack()
                  setPageNumber(1)
                }}>
                  <img src={GlobasearchWhite} alt="" />
                  <Typography variant='h4'>{PAGETITLE}</Typography>
                </div>
              </div>
              <div className='searchheader-search'>
                <SearchPanel notchang={true} parent_func={parent_handleKeyDownSearchClick} />
              </div>
              <div className='search_header_dtl'>
                <span className='border_rightside'><ShareIcon /></span>
                <span><HelpIcon /></span>
              </div>
            </div>
          </div>
          <div>
            <Box className='tabmainHeader' sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={valueTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '15px' }}>
                  <div className='TabMain'>
                    <div className='TabMain_btn'>
                      <span>CONVERSE</span>
                      <img className='Sparklemain' src={Sparkle} />
                      <img className='SparklemainsubTop' src={Sparkle} />
                      <img className='Sparklemainsubbottom' src={Sparkle} />
                    </div>
                    <div className='TabMain_Tabs'>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="All" value="1" />
                        <Tab label="People" value="2" />
                        <Tab label="Projects" value="3" />
                        <Tab label="Services" value="4" />
                        <Tab label="Documents" value="5" />
                        <Tab label="More" value="6" icon={<MoreVertIcon style={{ margin: 0 }} />} iconPosition="start" />
                      </TabList>
                    </div>
                    <div className='TabMain_Mark'>
                      <p>
                        DEVELOPED WITH <span>Google Cloud</span>
                      </p>
                    </div>
                  </div>
                </Box>
                <TabPanel value="1" style={{ padding: 0 }}>

                  <div>
                    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                      <Drawer
                        className='mydrawersection searchgriddrawer'
                        variant='permanent'
                        open={opendrawer}
                        style={{ position: 'sticky !important' }}
                      >
                        <div className='leftside-sectionQues' style={{ height: elementHeight }}>
                          {opendrawer ?
                            <div>
                              <div className='main-questionheader' style={{ padding: '5px 0px 5px 0' }}>
                                <div className='main-questionheader-left'>
                                  <div className='projet-title'>
                                    <p>Facets</p>
                                  </div>
                                </div>
                                <div className='main-questionheader-rig'>
                                  <ArrowBackIcon
                                    style={{ fontSize: 30 }}
                                    onClick={handleDrawerClose}
                                    className='header-icons-back'
                                  />
                                </div>
                              </div>
                              <div className='layout-new_left'>
                                <div className='hist_container'>
                                  <div className='searchdetalmian'>
                                    {facets && facets.length > 0 ?
                                      <>
                                        {facets?.map((c, idx) => {
                                          return (
                                            <div className='searchdetal' key={idx}>
                                              <Collapse expandIconPosition='right' activeKey={activeSideMenuKey} onChange={handleSidePanelChange}>
                                                <Panel style={{ fontWeight: 400 }} header={c?.key} key={c?.key}>
                                                  {c?.values && c?.values?.length > 0 ? (
                                                    [...c.values].sort((a, b) => b.count - a.count).map((v, i) => {
                                                      return (
                                                        <div className='hist_cardcollapse' key={i}>
                                                          <p className='his_p'>
                                                            {v?.value === "" ? "UnSpecified" : v?.value}
                                                          </p>
                                                          <span>{v?.count}</span>
                                                        </div>
                                                      )
                                                    })) : <div className='hist_cardcollapse'>
                                                    <p> No history found!</p>
                                                  </div>}
                                                </Panel>
                                              </Collapse>
                                            </div>
                                          )
                                        })}
                                      </> : <div>No Record Found</div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div> : <div className='main-questionheader main-questionheader-close'>
                              <div className='main-questionheader-rig'>
                                <ArrowForwardIcon
                                  onClick={handleDrawerOpen}
                                  className='header-icons-back'
                                />
                              </div>
                            </div>
                          }
                        </div>
                      </Drawer>
                      <Box style={{ flex: 1 }}>
                        <div className=''>
                          {hasRunSearch && !showConverse && correctedQuery?.corrected_query !== '' &&
                            <div className='showsearchAgain'>
                              <div className='showsearchAgain_sub'>
                                <h6 className='newshowsearchAgain'>Showing results for<span onClick={() => handleKeyDownSearchClickBox(correctedQuery?.corrected_query, true)}>{correctedQuery?.corrected_query}</span></h6>
                                <h6 className='oldshowsearchAgain'>Did you mean<span onClick={() => handleKeyDownSearchClickBox(correctedQuery?.query, false)}>{correctedQuery?.query}</span></h6>
                              </div>
                            </div>
                          }
                          {hasRunSearch && <SummaryPanel />}
                          {hasRunSearch && (
                            showConverse
                              ? <ConversePanel />
                              : <div className='reultSearch'>
                                {recommendedQues && recommendedQues !== '' && recommendedQues?.length > 0 &&
                                  <div className='recommandedQuesMain'>
                                    <h4>Recommended Questions :</h4>
                                    {recommendedQues?.map((c, idx) => {
                                      return (
                                        <div className='recommandedQues' key={idx} onClick={() => handleKeyDownSearchClickBox(c)}>
                                          <span>{c}</span>
                                          <Search />
                                        </div>
                                      )
                                    })}

                                  </div>
                                }
                                <ResultPanel pageNumber={pageNumber} setPageNumber={setPageNumber} />
                              </div>)
                          }
                        </div>
                      </Box>
                      {/* <Box style={{ display: 'flex', width: '20%', height: '50%'}}>
                      <div className='rightside-settings'>
                        <div className='dropMain'>
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                          <InputLabel id="dropdown-label">Model</InputLabel>
                          <Select
                            style={{
                              height: "20px"
                            }}
                            value={modelName}
                            onChange={handleModelChange}
                          >
                            {menuItems}
                          </Select>
                          </FormControl>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <p className='sliderName'>Temperature:</p>
                          <Input
                              value={tempValue}
                              margin="dense"
                              onChange={(e) => setTempValue(parseFloat(e.target.value))}
                              inputProps={{
                                  step: 0.1,
                                  min: 0,
                                  max: 1,
                                  type: 'number',
                              }}
                              style={{width: '60px'}}
                          />
                        </div>
                        <Slider
                            aria-label="Always visible"
                            defaultValue={0}
                            step={0.1}
                            marks={marks}
                            max={1}
                            size='small'
                            value={tempValue}
                            onChange={(e, v) => setTempValue(v)}
                            valueLabelDisplay="auto"
                            className='slider'
                        />
                      </div>
                    </Box> */}
                  </Box>
                  </div>

                </TabPanel>
                <TabPanel value="2"></TabPanel>
                <TabPanel value="3"></TabPanel>
                <TabPanel value="4"></TabPanel>
                <TabPanel value="5"></TabPanel>
                <TabPanel value="6"></TabPanel>
              </TabContext>
            </Box>

          </div>

        </div>

      }

    </div>
  )
}

const ErrorBar = () => {
  const { errors, clearError } = useAppContext()
  return errors.length === 0 ? <></> : (
    <div className='error-bar'>
      {errors.map((e) => {
        return <Alert style={{ width: '50%' }} key={e.id} onClose={() => clearError(e.id)} severity="error">{e.msg}</Alert>
      })}
    </div>
  )
}



const WrapComponent = ({ Component, ...props }) => {

  const navigate = useNavigate()


  return (
    <div className='main_container'>

      <section className='main-screen'>
        <Header />
        <div className='main-section'>
          <div className='main-container'>
            <Component {...props} />
          </div>
        </div>
      </section>
    </div>
  )
}

const AppInner = () => {
  const { isLoggedIn, cookies } = useAppContext()
  const redirectLogin = () => isLoggedIn() ? redirect('/agent') : null

  const logout = () => {
    cookies.remove('OK')
    return redirect('/login')
  }


  const router = createBrowserRouter([
    { path: "login", loader: redirectLogin, element: <PasswordPage /> },
    { path: "agent", element: <WrapComponent Component={SearchAgentApp} /> },
    { path: "/settings", element: <WrapComponent Component={SettingsPage} /> },
    { path: "logout", loader: logout, element: <></> },
    { path: "/", loader: () => redirect('/login'), element: <></> }
  ]);

  return (
    <div className="App"><RouterProvider router={router} /></div>
  )
}

function App() {
  return (
    <AppContextProvider><AppInner /></AppContextProvider>
  );
}

export default App;
