import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactMarkdown from 'react-markdown'


const MarkdownTableRenderer = ({ markdownText }) => {
    // console.log("LINE     ==================>", markdownText)

    const parts = markdownText?.split('|');

    // Extract the text before the first | character
    const extractedText = parts[0]?.trim();
    // Extract the text after the last | character, join the parts, and trim whitespace
    const lastIndex = markdownText?.lastIndexOf("|");

    // Extract the text after the last | character
    const extractedTextLast = markdownText?.substring(lastIndex + 1)?.replace(/\s+/g, ' ')?.trim();

    // Output the extracted text to the console
    // console.log(extractedTextLast);


    const lines = markdownText?.replace(/-/g, '').split('\n');

    let isTable = false;
    const tableRows = [];

    for (const line of lines) {
        if (line?.includes('|')) {
            if (!isTable) {
                isTable = true;
            }
            const rowData = line
                .split('|')
                .slice(1, -1) // Ignore the first and last empty elements
                .map(cell => cell.trim());
            tableRows?.push(rowData);

        } else {
            if (isTable) {
                break; // Exit loop when the table ends
            }
        }
    }


    if (!isTable) {
        return <div><ReactMarkdown>{markdownText}</ReactMarkdown></div>;
    }

    return (
        // <table>
        //     <thead className='tb_head_mrk'>
        //         <tr>
        //             {tableRows[0]?.map((header, index) => (
        //                 <th key={index}>{header}</th>
        //             ))}
        //         </tr>
        //     </thead>
        //     <tbody>
        //         {tableRows?.slice(1)?.map((row, rowIndex) => (
        //             <tr key={rowIndex}>
        //                 {row?.map((cell, cellIndex) => (
        //                     <td key={cellIndex}>{cell}</td>
        //                 ))}
        //             </tr>
        //         ))}
        //     </tbody>
        // </table>
        <>
            {extractedText?.length ?
                <div><ReactMarkdown>{extractedText}</ReactMarkdown></div>
                : null
            }
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {tableRows[0]?.map((header, index) => (
                                <TableCell key={index}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows?.slice(1)?.map((row, rowIndex) => (
                            <TableRow
                                key={rowIndex}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {row?.map((cell, cellIndex) => (
                                    <TableCell align="left" key={cellIndex}>{cell}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {extractedTextLast ?
                <div><ReactMarkdown >{extractedTextLast}</ReactMarkdown></div >
                : null
            }
        </>

    );
}

export default MarkdownTableRenderer;
