const doctypesList = [
  "Annual Report on Portfolio Performance",
  "IEG Evaluation",
  "Audit",
  "Agreement,Grant or Trust Fund Agreement",
  "Agreement,Memorandum",
  "Agreement,Russian Federation",
  "Agreement,Side Letter",
  "Aide Memoire",
  "Announcement",
  "Announcement,President's Speech",
  "Announcement,Press Release",
  "Annual Report",
  "Annual Report,IEG Evaluation",
  "Auditing Document,Financial Monitoring Report,Letter,Memorandum",
  "Auditing Document,Financial Statement",
  "Board Documents",
  "Board Report",
  "Board Report,Monthly Operational Summary",
  "Board Summary",
  "Brief",
  "Brief,Report",
  "CAS Completion Report Review",
  "CAS Progress Report",
  "CAS Public Information Note",
  "City Development Strategy (CDS)",
  "Commodities Study",
  "Commodity Working Paper",
  "Completion Point Document",
  "Corporate Governance Assessment (ROSC)",
  "Correspondence",
  "Country Assistance Evaluation",
  "Country Assistance Strategy Document",
  "Country Climate and Development Report",
  "Country Economic Memorandum",
  "Country Engagement Note",
  "Country Environmental Analysis (CEA)",
  "Country Financial Accountability Assessment",
  "Country Gender Assessment (CGA)",
  "Country Infrastructure Framework",
  "Country Portfolio Performance Review",
  "Country Procurement Assessment (CPAR)",
  "Country Re-engagement Note",
  "CPF Completion and Learning Review Review",
  "Credit Agreement",
  "Data Model",
  "Dataset",
  "Debt and Creditworthiness Study",
  "Debt Management Performance Assessment",
  "Decision Point Document",
  "Deliverable Document",
  "Departmental Working Paper",
  "Development Policy Review (DPR)",
  "Directory",
  "Disbursement Letter",
  "Disclosable Project Appraisal Document (PAD)",
  "Economic and Sector Work Assessment",
  "Economic Report",
  "Economic Updates and Modeling",
  "Education Sector Review",
  "Energy Study",
  "Energy-Environment Review",
  "Environment Working Paper",
  "Environmental Action Plan,Social Action Plan",
  "Environmental Analysis,Environmental Assessment,Social Assessment",
  "Environmental Analysis,Resettlement Plan",
  "Environmental and Social Assessment,Environmental and Social Management Framework",
  "Environmental and Social Assessment,Environmental and Social Management Plan",
  "Environmental and Social Assessment,Environmental and Social Management Plan,Report",
  "Environmental and Social Assessment,Procedure and Checklist",
  "Environmental and Social Assessment,Report",
  "Environmental and Social Assessment,Social Assessment",
  "Environmental and Social Commitment Plan",
  "Environmental and Social Framework",
  "Environmental and Social Management Framework",
  "Environmental and Social Management Plan",
  "Environmental and Social Management Plan,Indigenous Peoples Plan",
  "Environmental and Social Management Plan,Procedure and Checklist",
  "Environmental and Social Management Plan,Resettlement Plan",
  "Environmental and Social Review Summary",
  "Environmental Assessment",
  "Environmental Assessment,Environmental and Social Assessment,Social Assessment",
  "ESMAP Paper",
  "Executive Director's Statement",
  "Financial Assessment",
  "Financial Flows",
  "Financial Sector Assessment Program",
  "Financial Statement",
  "Financing Agreement",
  "Financing Agreement,Project Agreement",
  "Flash Report",
  "Foreign Trade, FDI, and Capital Flows Study",
  "Funding Proposal",
  "GEF Project Brief",
  "GEF Project Document",
  "General Economy, Macroeconomics and Growth Study",
  "Global Development Finance - formerly World Debt Tables",
  "Global Environment Facility Working Paper",
  "Governor's Statement",
  "Grant or Trust Fund Agreement",
  "Guarantee Agreement",
  "Guideline",
  "Health Sector Review",
  "Human Capital Review",
  "Human Capital Working Paper",
  "IEG Evaluation",
  "IEG Evaluation,Publication",
  "Impact Evaluation Report",
  "Implementation Completion and Results Report",
  "Implementation Completion Report Review",
  "Implementation Status and Results Report",
  "Indigenous Peoples Plan",
  "Indigenous Peoples Plan,Resettlement Plan",
  "Information Notice",
  "Insolvency Assessment (ROSC)",
  "Inspection Panel Notice of Registration",
  "Inspection Panel Report and Recommendation",
  "Institutional and Governance Review (IGR)",
  "Integrated Safeguards Data Sheet",
  "Integrative Fiduciary Assessment",
  "Interim Strategy Note",
  "Internal Discussion Paper",
  "Investigation Report",
  "Investment Climate Assessment (ICA)",
  "Issues Paper",
  "Journal Article",
  "Knowledge Economy Study",
  "LAC Human & Social Development Group Paper Series",
  "Law and Justice Study",
  "Legal and Judicial Sector Assessment",
  "Legal Opinion",
  "Letter",
  "Letter of Development Policy",
  "Loan Agreement",
  "Loan Agreement,Side Letter",
  "Managing Director's Report",
  "Manual",
  "Memorandum",
  "Memorandum & Recommendation of the Managing Director",
  "Memorandum & Recommendation of the Director",
  "Memorandum & Recommendation of the President",
  "Mining/Oil and Gas",
  "Minutes",
  "Monthly Operational Summary",
  "Newsletter",
  "Note on Cancelled Operation",
  "Other Agricultural Study",
  "Other Education Study",
  "Other Environmental Study",
  "Other Financial Accountability Study",
  "Other Financial Sector Study",
  "Other Health Study",
  "Other Infrastructure Study",
  "Other Poverty Study",
  "Other Procurement Study",
  "Other Public Sector Study",
  "Other Rural Study",
  "Other Social Protection Study",
  "Other Urban Study",
  "Performance and Learning Review",
  "Policy",
  "Policy Note",
  "Policy Paper",
  "Policy Research Working Paper",
  "Poverty & Social Policy Working Paper",
  "Poverty Assessment",
  "Poverty Reduction Strategy Paper (PRSP)",
  "Pre-2003 Economic or Sector Report",
  "Preliminary Decision Point Document",
  "President's Report",
  "President's Speech",
  "Price Prospects for Major Primary Commodities",
  "Procedure",
  "Procedure and Checklist,Report",
  "Procurement Assessment",
  "Procurement Plan",
  "Program Appraisal Document (PAD)",
  "Program Document",
  "Program Information Document",
  "Program-for-Results Environmental and Social Systems Assessment",
  "Program-for-Results Fiduciary Systems Assessment",
  "Program-for-Results Technical Assessment",
  "Project Agreement",
  "Project Appraisal Document Data Sheet",
  "Project Appraisal Document;Agreement",
  "Project Completion Report",
  "Project Concept Note",
  "Project Document",
  "Project Implementation Plan",
  "Project Information and Integrated Safeguards Data Sheet",
  "Project Information Document",
  "Project Paper",
  "Project Performance Assessment Report",
  "Project Preparation Facility Documents",
  "PSD, Privatization and Industrial Policy",
  "Public Environmental Expenditure Review (PEER)",
  "Public Expenditure Review",
  "Public Investment Review",
  "Publication",
  "Publication;IEG Evaluation",
  "Recent Economic Developments in Infrastructure (REDI)",
  "Reference Material",
  "Report",
  "Report,Stakeholder Engagement Plan",
  "Resettlement Plan",
  "Resettlement Plan,Social Assessment",
  "Risk and Vulnerability Assessment",
  "Rural Development Assessment",
  "SAEA3",
  "Safeguards Diagnostic Review",
  "Sanctions Board Decision",
  "Sector or Thematic Evaluation",
  "Sector Report",
  "Side Letter",
  "Social Action Plan",
  "Social Analysis",
  "Social Assessment",
  "Staff Appraisal Report",
  "Staff Working Paper",
  "Stakeholder Engagement Plan",
  "Statutory Committee Report",
  "Strategic Environmental Assessment/Analysis",
  "Supervision Report",
  "Systematic Country Diagnostic",
  "Technical Annex",
  "Technical Assessment",
  "The Environmental and Social Review Summary",
  "Tranche Release Document",
  "Transcript",
  "Transitional Support Strategy",
  "Trust Fund Administrative Agreement",
  "Viewpoint",
  "Visual Material",
  "Water & Sanitation Discussion Paper",
  "WBI Working Paper",
  "Women in Development and Gender Study",
  "Working Paper",
  "Working Paper (Numbered Series)",
  "World Bank Annual Report",
  "World Bank Atlas",
  "World Development Indicators",
  "World Development Report"
]
// make set and sort it and back to list
export const doctypes = Array.from(new Set(doctypesList)).sort()

