import React, { useEffect, useState,useRef } from 'react'
import './settings.css';
import axios from 'axios'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { TextField } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Papa from 'papaparse';
import { Button, message, Upload, Input } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search'


const SettingsPage = () => {

    const [editsetting, setEditsetting] = useState(false)
    const [editPrompt, setEditPrompt] = useState(false)
    const [editacronymPrompt, setEditacronymPrompt] = useState(false)
    const [projectName, setProjectName] = useState("")
    const [promptEditable, setPromptEditable] = useState(true)
    const [promptEditableacronym, setPromptEditableacronym] = useState(null)
    const [csvDataInitial, setCsvDataInitial] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [editmood, setEditmood] = useState(false);
    const [headers, setHeaders] = useState(["Acronym", "FullForm"]);
    const [search, setSearch] = useState('')
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [showdelete, setShowdelete] = useState(false);
    const lastRowRef = useRef(null);
    const [changesMade, setChangesMade] = useState(false)

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (changesMade) {
            // Most modern browsers do not support custom messages anymore and will show
            // their own default message for beforeunload events.
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message; // Gecko, Trident, Chrome 34+
            return message; // Gecko, WebKit, Chrome <34
          }
        };
      
        // Add the event listener for beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);
      
        // Remove the event listener when the component is unmounted
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, [changesMade]); 

    const handleacronym = () => {
        setEditacronymPrompt(true)
        setEditsetting(true)
        getacronymData()
        setEditPrompt(false)
    }

    const handleEditPrompt = () => {
        setEditsetting(true)
        setEditPrompt(true)
        getbtnhandle()
        setPromptEditable(true)
        setEditacronymPrompt(false)
    }

    const savebtnhandleacronym = async (result, intents) => {
        console.log('csvData 42', csvData)
        return axios.post('/api/add-synonym',
            {
                data: csvData,
            }
        )
            .then(r => {
                const { data } = r
                if (data?.success) {
                    setPromptEditableacronym(null)
                    setEditmood(false)
                    getacronymData()
                    setChangesMade(false)

                }
            }).catch(e => {
                console.log('e', e)

            })
    }

    const savebtnhandle = async (result, intents) => {
        if (projectName !== "") {
            return axios.post('/api/save-prompt',
                {
                    prompt: projectName,
                    model: 'bison'
                }
            )
                .then(r => {
                    const { data } = r
                    if (data?.success) {
                        setPromptEditable(true)
                        getbtnhandle()
                    }
                }).catch(e => {

                })
        }
    }

    const getbtnhandle = () => {
        return axios.get('/api/prompt')
            .then(r => {
                const { data } = r
                if (data?.success) {
                    setProjectName(data?.result?.prompts)
                }
            }).catch(e => {

            })
    }
    
    const getacronymData = () => {
        return axios.get('/api/synonym')
            .then(r => {
                const { data } = r
                if (data?.success) {
                    console.log('data?.result?', data?.result)
                    setCsvData(data?.result?.data);
                    setCsvDataInitial(data?.result?.data);
                    // setHeaders(Object.keys(data[0]))
                }
            }).catch(e => {

            })
    }

    const parseCsv = (file) => {
        const reader = new FileReader();
        setChangesMade(true)
        
        reader.onload = (e) => {
            const csvText = e.target.result;
            Papa.parse(csvText, {
                complete: (result) => {
                    let data = result?.data?.filter(item => Object.values(item).every(value => value !== ''))
                    setCsvData([...csvData, ...data]);
                    setCsvDataInitial([...csvData, ...data]);
                    // setHeaders(Object.keys(data[0]))
                },
                header: true,
            });
        };
        reader.readAsText(file);
    };

    const handleChangeCSV = (value, rowInd, key) => {
        setChangesMade(true)
        csvData[rowInd][key] = value
        console.log('*', { value, rowInd, key })
    }

    const beforeUpload = (file) => {
        const isCsv = file.type === 'text/csv';
        if (!isCsv) {
            message.error('You can only upload CSV files!');
        } else {
            parseCsv(file);
        }
        return false;
    };

    const addRow = () => {
        let keys = ['Acronym', 'FullForm']
        let resultArray = {};
        for (let i = 0; i < keys?.length; i++) {
            let key = keys[i];
            let value = '';
            resultArray[key] = value
        }
        let newarray = [...csvData, resultArray]
        setCsvData(newarray);
        setCsvDataInitial(newarray);
        editcsvRow(newarray.length - 1)
        if (lastRowRef.current) {
            lastRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            // window.scrollTo(0, window.scrollY + lastRowRef.current.getBoundingClientRect().height);
        }
    }

    const editcsvRow = (rowInd) => {
        setPromptEditableacronym(rowInd)
        setEditmood(true)
        setChangesMade(true)
    }

    const deletecsvRow = (indexToRemove) => {
        if (indexToRemove >= 0 && indexToRemove < csvData.length) {
            console.log('rowInd', indexToRemove)
            let newArray = csvData.filter((element, index) => index !== indexToRemove);
            setCsvData(newArray)
            setCsvDataInitial(newArray)
            setSelectedRows([])
            setSelectAll(false);
        } else {
            console.error("Invalid index to remove.");
        }
    }

    const cancelRow = (rowInd) => {
        setPromptEditableacronym(null)
    }

    useEffect(() => {
        const allRowsSelected = csvData.length > 0 && selectedRows.length === csvData.length;
        setSelectAll(allRowsSelected);
        if (selectedRows?.length > 0) {
            setShowdelete(true)
        } else {
            setShowdelete(false)
        }
    }, [selectedRows]);

    const handleRowSelection = (rowIndex) => {
        const selectedIndex = selectedRows.indexOf(rowIndex);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, rowIndex);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
        setSelectedRows(newSelected);
    };

    const handleDeleteRows = () => {
        const updatedCsvData = csvData.filter((row, index) => !selectedRows.includes(index));
        setCsvData(updatedCsvData);
        setCsvDataInitial(updatedCsvData);
        setSelectedRows([]);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
            setSelectAll(false);
        } else {
            setSelectedRows([...Array(csvData.length).keys()]);
            setSelectAll(true);
        }
    };

    useEffect(() => {
        if (!search?.length) {
            getsearchresult()
        }
    }, [search])


    const getsearchresult = () => {
        if (search !== '') {
            let datasearch = csvDataInitial?.filter((element) => element.Acronym.toLowerCase().includes(search.toLowerCase()));
            if (datasearch) {
                setCsvData(datasearch);
            }
            else {
                setCsvData([]);
            }
        }
        else {
            setCsvData(csvDataInitial);
        }
    }

    return (
        <div className='settingsMain'>
            {!editsetting ?
                <div className='settingsSub'>
                    <h2>Settings</h2>
                    <div className='settingsMain_Child'>
                        <h6>Prompt Change</h6>
                        <span onClick={handleEditPrompt}><BorderColorOutlinedIcon /></span>
                    </div>
                    <div className='settingsMain_Child'>
                        <h6>Acronym</h6>
                        <span onClick={handleacronym}><BorderColorOutlinedIcon /></span>
                    </div>
                </div>
                :
                <div className='settingsEditMode'>
                    <div className='settingsEditMode_sub'>
                        <div className='txtCenter'><span onClick={() => setEditsetting(false)} className='cancelTop'><CloseOutlinedIcon /></span></div>
                        {editPrompt &&
                            <div>
                                <div className="input-design">
                                    <h2 className="inputheading">Prompt</h2>
                                    <TextField
                                        fullWidth
                                        multiline
                                        maxRows={15}
                                        rows={15}
                                        // defaultValue="Default Value"
                                        variant="outlined"
                                        className="inputdatatag"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        autoComplete={false}
                                        disabled={promptEditable}
                                    />
                                </div>
                                <div className='txtCenter flexColumn'>
                                    <button onClick={() => setPromptEditable(false)} className='btnEditPrompt btnSimilar'> <EditIcon /> <span>Edit</span></button>
                                    <button onClick={savebtnhandle} className='btnSavePrompt btnSimilar'><SaveIcon /><span>Save</span></button>
                                </div>
                            </div>
                        }
                        {
                            editacronymPrompt &&
                            <div>
                                <div className="input-design">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <Button type="primary" style={{ marginRight: '10px' }} onClick={addRow}>Add Row</Button>
                                            <Upload beforeUpload={beforeUpload} showUploadList={false}>
                                                <Button>Select CSV File</Button>
                                            </Upload>
                                            {
                                                showdelete == true &&
                                                <Button style={{ marginLeft: '10px' }} onClick={handleDeleteRows}>Delete</Button>
                                            }
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                placeholder="Search"
                                                className='login-input pw-input antd-input-border-customize'
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        getsearchresult()
                                                    }
                                                }}
                                                allowClear
                                                suffix={<SearchIcon
                                                    onClick={() => {
                                                        getsearchresult()
                                                    }}
                                                />}
                                            />
                                        </div>
                                    </div>
                                    <div className='TableUploadMain'>
                                        <div className='TableUploadthead'>
                                            {csvData.length > 0 && (
                                                <div className='TableUploadthead_head'>
                                                    <div className='checkboxContainer'>
                                                        <Checkbox
                                                            checked={selectAll}
                                                            onChange={handleSelectAll}
                                                        />
                                                    </div>

                                                    {Object.keys(csvData[0]).map((header, index) => (
                                                        <span key={index}>{header}</span>
                                                    ))}
                                                    <span style={{ width: '110px' }}>Actions</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className='tablemainBody'>
                                            {csvData && csvData.length > 0 && csvData.map((row, rowIndex) => (
                                                <div className='TableUploadbody' ref={rowIndex === csvData.length - 1 ? lastRowRef : null} key={rowIndex}>
                                                    <div className='checkboxContainer'>
                                                        <Checkbox
                                                            checked={selectedRows.includes(rowIndex)}
                                                            onChange={() => handleRowSelection(rowIndex)}
                                                        />
                                                    </div>
                                                    {Object.keys(row).map((value, columnIndex) => (
                                                        <span className='csvMain' key={columnIndex}>
                                                            {promptEditableacronym === rowIndex ? (
                                                                <div className='csvinputview'>
                                                                    <input defaultValue={row[value]} type='text' onChange={(e) => handleChangeCSV(e.target.value, rowIndex, value)} />
                                                                </div>
                                                            ) : (
                                                                <div className='csvinputdisplay'>
                                                                    {columnIndex === 0 ? (
                                                                        <p style={{ margin: 0 }} key={columnIndex}>{row[value]}</p>
                                                                    ) : (
                                                                        <p style={{ margin: 0 }} key={columnIndex}>
                                                                            {row[value].split(',').map((item, index) => (
                                                                                item.trim() !== "" && (
                                                                                    <div className='chipsFullForm' key={index}>
                                                                                        <p style={{ margin: 0 }}>{item.trim()}</p>
                                                                                    </div>
                                                                                )

                                                                            ))}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </span>
                                                    ))}
                                                    <span style={{ width: '110px' }}>
                                                        {promptEditableacronym !== rowIndex ? (
                                                            <div className='btnaction'>
                                                                <span onClick={() => editcsvRow(rowIndex)}><BorderColorOutlinedIcon style={{ marginRight: '10px', paddingRight: '10px', borderRight: '1px solid #ddd' }} /></span>
                                                                <span onClick={() => deletecsvRow(rowIndex)}><DeleteOutlineOutlinedIcon /></span>
                                                            </div>
                                                        ) : (
                                                            <div className='btnaction'> <span onClick={() => cancelRow(rowIndex)}><CloseIcon /></span></div>
                                                        )}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='txtCenter flexColumn'>
                                    <Button type="primary" onClick={savebtnhandleacronym} >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </div >
            }
        </div >
    )
}


export default SettingsPage