import axios from 'axios'
import { useContext, createContext, useState, useReducer, useEffect } from 'react'
import { cloneDeep, get, set } from 'lodash'
import moment from 'moment'
import Cookies from 'universal-cookie';
import { message } from 'antd'
const { v4: uuidv4 } = require('uuid');
const COOKIE_EXPIRY_S = 3600
const DEBUG = (process.env.REACT_APP_DEBUG || false) === 'true'

const hashString = (s) => {
    let hash = 0, i, chr;
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
        chr = s.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return hash;
}

export const AppContext = createContext();

export const stateReducer = (state, payload) => {
    if (payload === 'clearObj') return {}
    if (payload === 'clearArray') return []
    let tmp = cloneDeep(state)
    Object.keys(payload).forEach((k) => {
        tmp[k] = payload[k]
    })
    return tmp
}

export const AppContextProvider = ({ children }) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [summary, setSummary] = useState('')
    const [result, setResult] = useState([])
    const [expandqury, setExpandqury] = useState('')
    const [intentDict, setIntentDict] = useState([])
    const [recommendedQues, setRecommendedQues] = useState([])
    const [hasRunSearch, setHasRunSearch] = useState(false)
    const [isHome, setIsHome] = useState(false)
    const [hasRunSummary, setHasRunSummary] = useState(false)
    const [redirectPage, setRedirectPage] = useState()
    const [converseQuery, setConverseQuery] = useState('')
    const [showConverse, setShowConverse] = useState(false)
    const [searchNewQuery, setSearchNewQuery] = useState(false)
    const [showSummary, setShowSummary] = useState(false)
    const [structuredApproach, setStructuredApproach] = useState('vector')
    const [loadingTime, setLoadingTime] = useState(0)
    const [thresholdValue, setThresholdValue] = useState('0.0')
    const [vertexthresholdValue, setVertexthresholdValue] = useState('0.0')
    const [bostValue, setBostValue] = useState('0')
    const [bostValueDecrement, setBostValueDecrement] = useState('0.04')
    const [regionBoost, setRegionBoost] = useState(false)
    const [recencyBoost, setRecencyBoost] = useState(false)
    const [countryBoost, setCountryBoost] = useState(false)
    const [docTypeBoost, setDocTypeBoost] = useState(false)
    const [docTypeFilter, setDocTypeFilter] = useState([])
    const [isPeopleIntent,setIsPeopleIntent] = useState("None")
    const [isProjectIntent,setIsProjectIntent] = useState("None")
    const [entitiesDict, setEntityDict] = useState([])
    const [isNextPage, setIsNextPage] = useState(false)
    const [totalResults, setTotalResults] = useState(0)
    const [maxPageAccess, setMaxPageAccess] = useState(1)
    const [facets, setFacets] = useState([])
    const [noOfRows, setNoOfRows] = useState(10)
    const [pageNo, setPageNo] = useState(1)
    const [tempValue, setTempValue] = useState(0.0);
    const [isWildCard, setIsWildCard] = useState(false)

    const modelDict = {
        0: ['gemini-pro', 'Gemini Pro'],
        1: ['text-bison@002', 'Bison'],
        2: ['claude-3-sonnet@20240229', 'Claude Sonnet']
    };
    const [modelName, setModelName] = useState(modelDict[1][0]);
    const [expandSynonym, setExpandSynonym] = useState(true)
    const [useClaude, setUseClaude] = useState(false)
    const [selectAll, setSelectAll] = useState(false);
    
    const [correctedQuery, setCorrectedQuery] = useReducer(stateReducer, {
        corrected_query: '',
        query: '',
        queriesnew: false
    })


    const [testData, setTestData] = useReducer((state, payload) => {
        switch (payload.action) {
            case 'clear':
                return []
            default:
                let tmp = payload.data
                tmp['id'] = state.length === 0 ? 1 : Math.max(...state.map(e => Number(e.id))) + 1
                return [tmp].concat(state)
        }
    }, [])

    const cookies = new Cookies();
    /* 
    STATE SETTING
    */
    const [errors, setErrors] = useReducer((state, payload) => {
        switch (payload.action) {
            case 'clear':
                return state.filter(e => e.id !== payload.id)
            default:
                let tmp = payload
                tmp['id'] = state.length === 0 ? 1 : Math.max(...state.map(e => Number(e.id))) + 1
                return [tmp].concat(state)
        }
    }, [])

    const [conversation, setConversation] = useReducer((state, payload) => {
        switch (payload.action) {
            case 'clear':
                return []
            default:
                let tmp = payload
                tmp['id'] = state.length === 0 ? 1 : Math.max(...state.map(e => Number(e.id))) + 1
                return [tmp].concat(state)
        }
    }, [])

    /*
     STATE HELPER FUNCTIONS
    */
    const addError = (msg) => setErrors({ action: 'add', msg: msg })
    const clearError = (id) => setErrors({ action: 'clear', id: id })

    const addConverseMsg = (msg, type) => setConversation({ action: 'add', msg: msg, type: type })
    const clearConversation = () => setConversation({ action: 'clear' })

    const addTest = () => {
        let data = {
            ts: moment().format(),
            query: searchQuery,
            loadingTimeS: loadingTime.toFixed(2),
            structuredApproach: structuredApproach,
            summary: summary,
            result: result,
            errors: errors
        }
        setTestData({ action: 'add', data: data })
    }

    const clearTests = () => setTestData({ action: 'clear' })

    const downloadTests = () => {
        // console.log(testData)
        let parsedData = 'ts\tquery\tloadingTimeS\tstructuredApproach\tsummary\tresult\terrors\n'
        parsedData += testData.map((r => Object.values(r).map(v => JSON.stringify(v)).join('\t'))).join('\n')
        const url = window.URL.createObjectURL(new Blob([parsedData]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `WB Test Queries ${moment().format()}.tsv`;
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const runAllTests = async (queries, structuredMethods) => {
        queries.forEach(async q => {
            setSearchQuery(q)
            await structuredMethods.forEach(async m => {
                setStructuredApproach(m)
                await handleCitation()
            })
        })
        downloadTests()
    }

    useEffect(() => {
        clearTests()
        let id = uuidv4()
        setSessionId(id)
        const expiry = moment().add(COOKIE_EXPIRY_S, 'seconds');
        cookies.set('session_id', true, {
            path: '/',
            expires: expiry.toDate(),
            sameSite: true,
            secure: true
        });
        // console.log('=======148----')
        // if (!!cookies.session_id) {
        //     setSessionId(cookies.session_id)
        //     console.log('session_id 137==>', cookies.session_id)
        // } else {
        //     let id = uuidv4()
        //     setSessionId(id)
        //     const expiry = moment().add(COOKIE_EXPIRY_S, 'seconds');
        //     cookies.set('session_id', true, {
        //         path: '/',
        //         expires: expiry.toDate(),
        //         sameSite: true,
        //         secure: true
        //     });
        //     console.log('=======148----')
        // }
    }, [cookies.session_id])

    const makeQueryId = (query_s) => {
        const hashVal = hashString(query_s)
        return `${sessionId}-${hashVal}`
    }

    const [log, setLog] = useReducer((state, payload) => {
        switch (payload.action) {
            case 'add':
                payload.ts = moment()
                return [payload].concat(state)
            default:
                return []
        }
    }, [])

    const [loadingState, setLoadingState] = useReducer(stateReducer, {
        app: false,
        conversation: false,
        search: false,
        summary: false
    })

    /* 
    Search & Converse
    */

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter' && !loadingState.search) {
            if (searchQuery !== '') {
                setPageNo(1)
                setNoOfRows(10)
                handleCitation()
            }
        }
    }
    const handleKeyDownSearchClick = (e) => {
        if (!loadingState.search) {
            if (searchQuery !== '') {
                setPageNo(1)
                setNoOfRows(10)
                handleCitation()
            }
        }
    }

    const handleKeyDownSearchClickBox = (e, correctednew = true) => {
        setSearchQuery(e)
        if (!loadingState.search) {
            if (e !== '') {
                setPageNo(1)
                setNoOfRows(10)
                handleCitation(true, e, true, correctednew)
            }
        }
    }

    const formateData = (value) => {
        if (value && value.includes('.')) {
            let [integerPart, decimalPart] = value.split('.');
            decimalPart = decimalPart.padEnd(2, '0').substring(0, 2);
            let formattedValue = `${integerPart}.${decimalPart}`;
            return formattedValue;
        } else {
            return `${value}.00`
        }
    }





    const handleCitation = async (preDefine = false, searchKey, search = true, correctednew = true, pageNum = 1, numRows = noOfRows) => {
        const formattedValue = formateData(bostValue)
        const formattedValuethres = formateData(thresholdValue)
        const formattedetVertexthr = formateData(vertexthresholdValue)
        const formattedValueBoostDec = formateData(bostValueDecrement)
        setCorrectedQuery({ 'corrected_query': '', 'query': '' })
        if (search) {
            if (pageNum === 1) {
                setSummary('')
                setShowConverse(false)
                clearConversation()
                setTotalResults(0)
                setFacets([])
                setHasRunSummary(false)
                setShowSummary(false)
                // setTotalResults(0)
            }
            setHasRunSearch(isHome)
            setLoadingState({ 'search': true })
        }
        setRecommendedQues([])
        setResult([])
        setExpandqury('')
        setIntentDict([])
        setEntityDict([])

        let result = []
        let resp = await axios.post('/api/citation', { query: preDefine ? searchKey : searchQuery, structured_approach: structuredApproach, disable_spell_check: correctednew, vertex_threshold: formattedValuethres, boost: formattedValue, boost_decrement: formattedValueBoostDec,vector_threshold : formattedetVertexthr, is_region_boosting: regionBoost, is_country_boosting: countryBoost, is_recency_boosting: recencyBoost,is_people_intent: isPeopleIntent, is_project_intent: isProjectIntent, is_doctype_boosting: docTypeBoost, doctype_filter: docTypeFilter,page_number:pageNum,page_size:numRows, expand_synonym: expandSynonym,is_wildcard_search:isWildCard})
        // console.log('resp==>', resp)
        // axios.post('/api/citation',
        //     {
        //         query: preDefine ? searchKey : searchQuery,
        //         structured_approach: structuredApproach,
        //     }
        // ).then(r => {
        const { data } = resp
        if (data?.result?.success) {
            setResult(data?.result?.results)
            console.log('setExpandqury', data?.result?.expanded_query)
            setExpandqury(data?.result?.expanded_query)
            setIntentDict(data?.result?.intent_dict)
            setEntityDict(data?.result?.entities)
            setIsNextPage(!!data?.result?.page_token)
            setCorrectedQuery({ 'corrected_query': data?.result?.corrected_query, 'query': data?.result?.query })
            result = data?.result?.results
            console.log(pageNum, 'page number')

            setHasRunSearch(true)
            if (pageNum === 1) {
                if (search) {
                    setHasRunSummary(false)
                    setShowSummary(false)
                }
            }
            console.log('SEARCH TIMESTAMPS', data?.result?.timestamps)
        }
        else {
            setHasRunSummary(false)
            setShowSummary(false)
            setLoadingState({ 'search': false })
            setLoadingState({ 'summary': false })
            setShowConverse(false)
            setConverseQuery('')
            return message.error(data?.message)
        }
        setLoadingState({ 'search': false })
        data?.result?.errors?.forEach(e => {
            console.error(e)
            addError(e)
        })
        addTest()
        if (data?.result?.results?.length > 0) {
            let intents = data?.result?.intents
            console.log('INTENTS', intents)
            let isDocumentIntent = false
            if (intents?.length > 0) {
                if (intents[0] === 'documents') {
                    isDocumentIntent = true
                }
            }
            if (data?.result?.vertex_summary && data?.result?.vertex_summary !== '' && isDocumentIntent) {
                setSummary(data?.result?.vertex_summary)
                setHasRunSummary(true)
                setShowSummary(true)
            }
            else {
                if (pageNum === 1) {
                    setLoadingState({ 'summary': true })
                    if (search) {
                        setTotalResults(data?.result?.total_size)
                        setFacets(data?.result?.facets)
                        console.log('facets', data?.result?.facets)
                        console.log('total size', data?.result?.total_size)
                        handleSearch(data?.result?.results, intents, data?.result?.expanded_query)
                    }
                }

            }
        }
        else {
            setLoadingState({ 'summary': false })
        }
        return result
        // }).catch(e => {
        //     console.error(e)
        //     addError(e)
        //     setLoadingState({ 'search': false })
        // })
    }


    // handle search take the result from citation and run it through the search api
    // take result array as input
    const handleSearch = async (result, intents, query) => {
        setSummary('')
        setShowSummary(false)
        setHasRunSummary(false)
        setShowConverse(false)
        clearConversation()
        setRecommendedQues([])
        console.log('MODEL NAME', modelName)
        console.log('TEMP VALUE', tempValue)
        console.log('USE CLAUDE', useClaude)
        return axios.post('/api/search',
            {
                query,
                session_id: sessionId,
                query_id: makeQueryId(searchQuery),
                structured_approach: structuredApproach,
                results: result.slice(0, 10),
                intents,
                show_claude: useClaude,
                temperature: tempValue,
                model_name: modelName

            }
        )
            .then(r => {
                const { data } = r
                if (data?.result?.success) {
                    setSummary(data?.result?.summary)
                    // const dataArray = data?.result?.recommended_questions.slice(1, -1).split(', ');
                    const dataArray = data?.result?.recommended_questions
                    setRecommendedQues(dataArray)
                    setHasRunSummary(true)
                    setShowSummary(true)
                    console.log('SUMMARY BY LLM TIMESTAMPS', data?.result?.timestamps)

                }
                data?.result?.errors?.forEach(e => {
                    console.error(e)
                    addError(e)
                })
                setLoadingState({ 'summary': false })

            }).catch(e => {
                console.error(e)
                addError(e)
                setLoadingState({ 'summary': false })
            })
    }

    const handleGoBack = () => {
        setHasRunSearch(false)
        setShowConverse(false)
        clearConversation()
        setSummary('')
        setResult([])
        setIntentDict([])
        setEntityDict([])
        setExpandqury('')

    }
    const handleKeyDownConverse = (e) => {
        if (e.key === 'Enter' && !loadingState.conversation) {
            if (converseQuery !== '') {
                handleConverse()
            }
        }
    }

    const runNewSearch = async (count, msg) => {
        if (count === 1) {
            setLoadingState({ 'conversation': true })
            // console.log("new search converse query",)
            var new_result = await handleCitation(true, msg, false)
            return axios.post('/api/search',
                { query: msg, results: new_result, follow_up: "new_search", session_id: sessionId, query_id: makeQueryId(msg) }
            ).then(async (r) => {
                const { data } = r
                if (data?.result?.success) {
                    setSearchQuery(msg)
                    setRecommendedQues([])
                    setSummary(data?.result?.summary)
                    const dataArray = data?.result?.recommended_questions.slice(1, -1).split(', ');
                    setRecommendedQues(dataArray)
                    setShowSummary(true)
                    setHasRunSummary(true)
                    setLoadingState({ 'conversation': false })
                    setSearchNewQuery(false)
                    setConverseQuery('')
                    setShowConverse(false)
                    clearConversation()
                }
            })
        }
        else {
            setSearchNewQuery(false)

        }
    }

    const handleConverse = async () => {
        setLoadingState({ 'conversation': true })
        console.log('state', correctedQuery.corrected_query, correctedQuery.query)
        return axios.post('/api/search',
            { query: converseQuery, results: result, follow_up: "follow_up", session_id: sessionId, query_id: makeQueryId(correctedQuery.query) ,show_claude: useClaude}
        ).then(async (r) => {
            const { data } = r
            if (data?.result?.success) {
                addConverseMsg(converseQuery, 'human')
                if (data?.result?.status?.includes('We are running a new search. Please wait.')) {
                    console.log('running new search')
                    // setSummary('We are running a new search. Please wait')
                    // setShowSummary(true)
                    // setHasRunSummary(true)
                    // addConverseMsg(data?.result?.status, 'ai')
                    addConverseMsg('I am sorry, I couldn\'t find any information related to your query.', 'ai')
                    setSearchNewQuery(true)
                    console.log('converse query', converseQuery)
                    // var new_result = await handleCitation(true, converseQuery, false)
                    // return axios.post('/api/search',
                    //     { query: converseQuery, results: new_result, follow_up: "new_search", session_id: sessionId, query_id: makeQueryId(converseQuery) }
                    // ).then(async (r) => {
                    //     const { data } = r
                    //     if (data?.result?.success) {
                    //         // setConversation(prevArray => {
                    //         //     const newArray = [...prevArray]; // Create a new array
                    //         //     newArray.splice(0, 1,data?.result.summary); // Remove the first element
                    //         //     return newArray; // Update the state with the new array
                    //         //   });
                    //         // addConverseMsg(data?.result?.summary, 'ai')
                    //         setRecommendedQues([])
                    //         setSummary(data?.result?.summary)
                    //         const dataArray = data?.result?.recommended_questions.slice(1, -1).split(', ');
                    //         setRecommendedQues(dataArray)
                    //         setShowSummary(true)
                    //         setHasRunSummary(true)
                    //         setLoadingState({ 'conversation': false })
                    //         // setLoadingState({ 'search': false })

                    //         setConverseQuery('')
                    //         setShowConverse(false)
                    //         clearConversation()
                    //     }
                    // })

                }
                else {
                    console.log('not running new search')
                    addConverseMsg(data?.result?.summary, 'ai')
                    setLoadingState({ 'conversation': false })
                }
                setConverseQuery('')
            }
            // data?.result?.errors.forEach(e => {
            //     console.error(e)
            //     addError(e)
            // })
            setLoadingState({ 'conversation': false })
        }).catch(e => {
            console.error(e)
            addError(e)
            setLoadingState({ 'conversation': false })
        }
        )
    }

    const checkLogin = async (attempt) => {
        setLoadingState({ app: true })
        return fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pw: attempt })
        }).then(r => r.json())
            .then(r => {
                if (r.success) {
                    const expiry = moment().add(COOKIE_EXPIRY_S, 'seconds');
                    cookies.set('OK', true, {
                        path: '/',
                        expires: expiry.toDate(),
                        sameSite: true,
                        secure: true
                    });
                    return Promise.resolve(true)
                } else {
                    cookies.remove('OK')
                    return Promise.resolve(false)
                }
            })
            .catch(() => {
                cookies.remove('OK')
                return Promise.resolve(false)
            })
            .finally(() => setLoadingState({ app: false }))
    }


    const isLoggedIn = () => DEBUG || !!cookies.get('OK')

    const contextValue = {
        errors,
        clearError,
        isLoggedIn,
        handleCitation,
        handleKeyDownSearch,
        handleKeyDownSearchClick,
        handleKeyDownConverse,
        log,
        loadingState,
        summary,
        setSummary,
        showSummary,
        setShowSummary,
        hasRunSummary,
        setHasRunSummary,
        result,
        hasRunSearch,
        setLoadingState,
        searchQuery,
        setSearchQuery,
        converseQuery,
        setConverseQuery,
        setLog,
        cookies,
        checkLogin,
        redirectPage,
        setRedirectPage,
        showConverse,
        structuredApproach,
        setStructuredApproach,
        setShowConverse,
        handleConverse,
        conversation,
        clearTests,
        loadingTime,
        setLoadingTime,
        downloadTests,
        testData,
        runAllTests,
        setIsHome,
        isHome,
        handleKeyDownSearchClickBox,
        handleGoBack,
        intentDict,
        recommendedQues,
        correctedQuery,
        setCorrectedQuery,
        setThresholdValue,
        setBostValue,
        bostValue,
        thresholdValue,
        setBostValueDecrement,
        bostValueDecrement,
        regionBoost,
        setRegionBoost,
        recencyBoost,
        setRecencyBoost,
        isPeopleIntent,
        setIsPeopleIntent,
        isProjectIntent,
        setIsProjectIntent,
        countryBoost,
        setCountryBoost,
        searchNewQuery,
        runNewSearch,
        setVertexthresholdValue,
        vertexthresholdValue,
        entitiesDict,
        setDocTypeBoost,
        docTypeBoost,
        docTypeFilter,
        setDocTypeFilter,
        isNextPage,
        setIsNextPage,
        totalResults,
        maxPageAccess,
        setMaxPageAccess,
        facets,
        expandqury,
        noOfRows,
        setNoOfRows,
        pageNo,
        setPageNo,
        expandSynonym,
        setExpandSynonym,
        useClaude,
        setUseClaude,
        selectAll,
        setSelectAll,
        tempValue,
        setTempValue,
        modelName,
        setModelName,
        modelDict,
        isWildCard,
        setIsWildCard
    }

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppContext = () => useContext(AppContext)
