import React from 'react'
import logo from '../../assets/earth.png'
import './Header.css';
import SearchIcon from '@mui/icons-material/Search';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate()
const handleClick =() =>{
    navigate('/settings')
}
const handleClickHome =() =>{
    navigate('/')
}
    return (
        <div className='main_header'>
            <div className='sub_header'>
                <div className='logomain'>
                  <div onClick={handleClickHome} style={{display : 'flex' , alignItems : 'center', cursor : 'pointer'}}>  <img src={logo} alt="" />
                    <span>WORLD BANK GROUP</span></div>
                </div>
                <div className='centerNav'>
                    <span className='border_rightside'>WORLD BANK </span>
                    <span className='border_rightside'>IFC</span>
                    <span className='border_rightside'>MIGA</span>
                    <span className=''>ICSID</span>
                </div>
                <div className='rightNav'>
                    <span className='search_icon'><SearchIcon /></span>
                    <span className='border_rightside'>People</span>
                    <span className='border_rightside'>HR</span>
                    <span className='border_rightside'>SA</span>
                    <span className='border_rightside'>Services</span>
                    <span className=''>Organization</span>
                    <span className='launchIcon'><RocketLaunchIcon /></span>
                    <span className='search_icon' onClick={handleClick}><SettingsIcon /></span>
                </div>
            </div>
        </div>
    )
}

export default Header